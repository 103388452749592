import * as React from 'react';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SearchIcon from '@mui/icons-material/Search';
import LockIcon from '@mui/icons-material/Lock';
import ShieldIcon from '@mui/icons-material/Shield';

export default function Homepage() {
    return (
        <Box
            maxWidth={1200}
            sx={{
                width: '100%',
            }}
        >
            <Typography variant="h1" align="center">
                Tools
            </Typography>
            <Typography variant="h5" align="center">
                Find the right tool for the job
            </Typography>
            <Box 
                sx={{ 
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'center'
                }} 
            >
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        boxShadow: '0 16px 32px rgba(0, 0, 0, 0.2)',
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: 'gray',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'gray',
                            },
                        },
                        width: { xs: '90%', sm: '75%' }
                    }}
                />
                </Box>
            <Box sx={{ marginTop: '50px', padding: '20px', borderRadius: '8px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    <Box sx={{ width: '300px', margin: '10px', textAlign: 'center' }}>
                        <LockIcon/>
                        <Typography variant="h6">Secure</Typography>
                        <Typography variant="body1">
                            Encryption-protocols are used, including SSL and TLS, to ensure that your data is fully protected during transmission.
                        </Typography>
                    </Box>
                    <Box sx={{ width: '300px', margin: '10px', textAlign: 'center' }}>
                        <HomeRepairServiceIcon/>
                        <Typography variant="h6">Extensive Toolkit</Typography>
                        <Typography variant="body1">
                            An extensive list of tools exist, from calculators to converters. Our toolkit is user-friendly, reliable and fast.
                        </Typography>
                    </Box>
                    <Box sx={{ width: '300px', margin: '10px', textAlign: 'center' }}>
                        <ShieldIcon/>
                        <Typography variant="h6">Privacy Focused</Typography>
                        <Typography variant="body1">
                            No tracking and no ads. Your data remains yours, all files are automatically erased from server storage.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}