import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, CssBaseline, Box, Skeleton, Container } from '@mui/material';
import NavBar from './NavBar';
import Footer from './Footer';
import '../../css/fonts.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const Layout = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', marginTop: '75px', height: '100vh'}}>
                {/* Left Skeleton Box */}
                <Box sx={{ width: '200px', justifyContent: 'center', display: {xs: 'none', lg: 'block'} }}>
                    <Skeleton 
                        variant="rectangular" 
                        width="100%" 
                        height={500} 
                        sx={{ display: loading ? 'block' : 'none' }} 
                    />
                </Box>

                {/* Main Content */}
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    {children}
                </Box>

                {/* Right Skeleton Box */}
                <Box sx={{ width: '200px', justifyContent: 'center', display: {xs: 'none', lg: 'block'} }}>
                    <Skeleton 
                        variant="rectangular" 
                        width="100%" 
                        height={500} 
                        sx={{ display: loading ? 'block' : 'none' }} 
                    />
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}

export default Layout;
