import React from 'react';
import { Box, Typography} from '@mui/material';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <Box 
            sx={{ 
                borderTop: '1px solid lightgray', 
                backgroundColor: 'white', 
                padding: '20px 0', 
                textAlign: 'center'
            }}
        >
            <Typography variant="body1" color="black">
                © {new Date().getFullYear()} ToolVoyage. All rights reserved.
            </Typography>
            <Box sx={{ marginTop: 1 }}>
                <Link to="/terms" style={{ textDecoration: 'none', color: 'black' }}>
                    Terms of Service
                </Link>
            </Box>
        </Box>
    );
}
