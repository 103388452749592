import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import logo from '../../images/logo.svg';

const LoadingScreen = ({ loading }) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [loading]);

  return (
    <Box 
      sx={{
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: loading ? 'flex' : 'none',
        position: 'fixed', 
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={logo} 
            alt="" 
            style={{ 
              width: '50px', 
              height: '50px', 
              marginRight: '10px',
              marginBottom: '-12.5px'
            }}
          />
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{
              fontSize: '4rem',
              color: '#333',
              marginTop: 2,
              animation: 'fade 1s ease-in-out forwards',
              '@keyframes fade': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 }
              }
            }}
          >
            ToolVoyage
          </Typography>
        </Box>
        <CircularProgress
          size={100}
          thickness={2}
          sx={{
            color: '#333',
            marginTop: 5,
            animation: 'spin 2s linear infinite',
            '@keyframes spin': {
              '0%': { transform: 'rotate(0deg)' },
              '100%': { transform: 'rotate(360deg)' }
            }
          }}
        />
        <Typography
          variant="body1"
          fontWeight={400}
          sx={{
            fontSize: '1.2rem',
            color: '#666',
            textAlign: 'center',
            marginTop: 2,
            animation: 'slide 1s ease-in-out forwards',
            '@keyframes slide': {
              '0%': { transform: 'translateY(20px)' },
              '100%': { transform: 'translateY(0)' }
            }
          }}
        >
          Voyaging the sea of tools...
        </Typography>
      </Box>
    </Box>
  );
}

export default LoadingScreen;