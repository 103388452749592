import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function ForbiddenPage() {
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
        >
            <Typography variant="h1" component="h1" >
                403
            </Typography>
            <Typography variant="h5" component="h2" color="textSecondary" gutterBottom>
                Forbidden resource
            </Typography>
            <Button 
                variant="outlined"
                component={Link} 
                color='gray'
                to="/" 
                sx={{ 
                    marginTop: '10px',
                    '&:hover': {
                        backgroundColor: 'gray',
                        color: 'white',
                    },
                }}
            >
                Go to Home
            </Button>
        </Box>
    );
}

export default ForbiddenPage;