import React from 'react';
import { Box, Typography } from '@mui/material';

function Terms() {
    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
        }}
        >
        <Typography
            variant="h4"
            fontWeight={600}
            sx={{
            fontSize: '2rem',
            color: '#333',
            marginBottom: 2,
            }}
        >
            Terms of Service
        </Typography>
        <Typography
            variant="body1"
            fontWeight={400}
            sx={{
            fontSize: '1.2rem',
            color: '#666',
            textAlign: 'center',
            }}
        >
            These are the terms of service.
        </Typography>
        </Box>
    );
}

export default Terms;