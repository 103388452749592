import * as React from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Button, Drawer, TextField, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../images/logo.svg';

export default function NavBar() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <Box>
            <Drawer
                anchor='top'
                open={drawerOpen}
                onClose={toggleDrawer}
            >  
            
            </Drawer>
            <AppBar 
                position='fixed' 
                sx={{ 
                    boxShadow: 'none',
                    backgroundColor: 'white', 
                    color: 'black', 
                    margin: '0 auto',
                    borderBottom: '1px solid lightgray'
                }}
            >
                <Container>
                    <Toolbar 
                        sx={{ 
                            flexDirection: { xs: 'row', sm: 'row' },
                            justifyContent: { xs: 'center', sm: 'space-between' }
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <img 
                                        src={logo} 
                                        alt="" 
                                        style={{ 
                                            height: '30px', 
                                            marginRight: '10px', 
                                            transition: 'transform 0.1s ease',

                                        }}
                                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                    />
                                </Box>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="div"
                                    sx={{ 
                                        flexGrow: 1, 
                                        display: { xs: 'none', sm: 'block' },
                                        textAlign: 'center',
                                    }}
                                    fontWeight={600}
                                >
                                    ToolVoyage
                                </Typography>
                            </Link>
                            <Button
                                sx={{
                                    display: { xs: 'flex', sm: 'none' },
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                }}
                                onClick={toggleDrawer}
                            >
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="div"
                                    sx={{ 
                                        flexGrow: 1, 
                                        display: { xs: 'block', sm: 'block' },
                                        textAlign: 'center',
                                        textTransform: 'none',
                                    }}
                                    fontWeight={600}
                                >
                                    ToolVoyage
                                </Typography>
                            </Button>
                        </Box>
                        
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                            <TextField
                                variant="outlined"
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                        height: '44px',
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'gray',
                                        },
                                    },
                                    width: '250px',
                                }}
                            />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}
