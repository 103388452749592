import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from './components/layout/Layout';
import LoadingScreen from './components/custom/LoadingScreen';

import Homepage from './components/pages/Homepage';
import Terms from './components/pages/Terms';

import NotFoundPage from './components/errors/NotFoundPage';
import ForbiddenPage from './components/errors/ForbiddenPage';
import UnauthorizedPage from './components/errors/UnauthorizedPage';

const AppContent = ({ loading }) => {
  return (
    <div 
      style={{
        opacity: loading ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
        pointerEvents: loading ? 'none' : 'auto',
      }}
    >
      <Layout>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/401" element={<UnauthorizedPage />} />
            <Route path="/403" element={<ForbiddenPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </Layout>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <LoadingScreen loading={loading} />
      <AppContent loading={loading} />
    </Router>
  );
}

export default App;